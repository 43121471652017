import Image from "@/shared/components/Image/new";
import styles from "./styles.module.css";
import { formatAuthorNames } from '@/shared/functions/formatAthorNames'

const Especial = (props) => {
    const { widgetTitle, title, author, subtitle, url, image } = props;
	const nextDomain = process.env.NEXT_PUBLIC_DOMAIN_URL
    
    return (
        <div className={`${styles["especial-parent"]}`}>
            <a href={url} className={`${styles["especial-container"]}`}>
                <div className={`${styles["especial-img"]}`}>
                    <Image 
                        src={image}
                        alt={title}
                        width={738}
                        height={371}
                        className={`${styles['img']}`}
                    />
                    {widgetTitle && <h3>{widgetTitle}</h3>}
                </div>
                <div className={`${styles["especial-info"]}`}>
                    {author && author.map((element) => {
                    const { imageUrl, _id, name, imageID} = element;
                    const authorNme = formatAuthorNames(name);
                    return (
                        <div key={_id}>
                            <Image 
                                src={`${nextDomain}/collections/authors/${imageID}`} 
                                alt={authorNme} 
                                width={80}
                                height={80}
                                className={`${styles['img']}`}
                                />
                        </div>
                    );
                    })}
                    {subtitle != "" && <p dangerouslySetInnerHTML={{ __html: subtitle }} />}
                    {title != "" && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
                    {author && author.map((element) => {
                        const { name, _id } = element;
                        const authorNme = formatAuthorNames(name);
                        const dataAuthor = { __html: 'Por ' + authorNme }
                        return (<h5 key={element + _id} dangerouslySetInnerHTML={dataAuthor} />)
                    })}
                </div>
            </a>
        </div>
    );
};
export default Especial;